<template>
    <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..."  class="dictSetting-container">
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <div class="common-screen-container">
            <div class="common-input-container">
                <span>字典名称/编码/值:</span>
                <el-input class="common-screen-input" v-model="params.keyword" placeholder="字典名称/编码/值" clearable></el-input>
            </div>
            <div class="common-input-container">
                <el-button class="common-screen-btn" type="primary" @click="() => {dictSetList()}">查 询</el-button>
                <el-button class="common-screen-btn clear" @click="() => {resetBtn()}" plain>重 置</el-button>
            </div>
        </div>
         <el-button class="common-screen-btn" type="primary" @click="addEditBtn()">新增字典</el-button>
        <el-table class="common-table" :data="tableData">
            <el-table-column label="名称" fixed="left">
                <template slot-scope="scope">
                    <div style="color: #1890ff;cursor: pointer;" @click="() => {detailBtn(scope.row.elementUuid)}">{{scope.row.elementName}}</div>
                </template>
            </el-table-column>
            <!-- <el-table-column :formatter="tableColumn" prop="elementName" label="名称"></el-table-column> -->
            <el-table-column :formatter="tableColumn" prop="elementUuid" label="编码"></el-table-column>
            <el-table-column :formatter="tableColumn" prop="dataValue" label="值"></el-table-column>
            <el-table-column :formatter="tableColumn" prop="sort" label="排序"></el-table-column>
            <el-table-column :formatter="tableColumn" prop="remark" show-overflow-tooltip label="备注"></el-table-column>
            <el-table-column width="120px" label="操作">
                <template slot-scope="scope">
                    <el-button @click="() => {addEditBtn(scope.row)}" type="text" size="small">编辑</el-button>
                    <el-button @click="() => {deleteBtn(scope.row)}" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: center"
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e, 'page')"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total"
            >
        </el-pagination>
        <el-dialog v-dialogDrag :title="ruleForm.id ? '编辑字典' : '新增字典'" class="setFailDialog" :append-to-body="true" width="530px" :visible.sync="addEditVisible" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="ruleRules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
                <el-form-item label="字典名称：" prop="elementName">
                    <el-input class="dialog-input" v-model="ruleForm.elementName" type="text"></el-input>
                </el-form-item>
                <el-form-item label="字典值：" prop="dataValue">
                    <el-input class="dialog-input" v-model="ruleForm.dataValue" type="text"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input class="dialog-input" v-model="ruleForm.sort" type="number"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input class="dialog-input" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleForm.remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="addEditVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => {dictAdd()}">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="删除" class="setFailDialog" :append-to-body="true" width="530px" :visible.sync="deleteVisible" :close-on-click-modal="false">
            <div>确认删除该条字典吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="deleteVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => {dictdelete()}">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn } from "../../utils/index.js"
import { dictSetList, dictAdd, dictdelete } from "../../service/common.js"
export default {
    components: {
        Breadcrumb,
    },
    data() {
        return {
            tableColumn,
            loading: false,
            BreadcrumbData: [
                { title: "首页", isLink: true, url: "/index" },
                { title: "字典", isLink: false },
                { title: "字典值设置", isLink: false },
            ],
            tableData: [],
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            params: {
                page: 1,
                pageSize: 10
            },
            addEditVisible: false,
            ruleForm: {
                elementName: '',
                dataValue: '',
                sort: '',
                remark: '',
            },
            ruleRules: { // 作废-校验规则
                elementName: [{ required: true, message: "请填写字典名称", trigger: "change" }],
                dataValue: [{ required: true, message: "请填写字典值", trigger: "change" }],
                remark: [{ required: false, message: "请填写字典名称", trigger: "change" }],
            },
            deleteVisible: false,
            deleteId: ''
        }
    },
    created() {
        this.dictSetList()
    },
    methods: {
        async dictdelete () { // 删除字典-提交
            let res = await dictdelete({id: this.deleteId})
            if (res.code == 200) {
                this.$message.success('删除成功')
                this.dictSetList()
                this.deleteVisible = false
            }
        },
        deleteBtn(row) { // 删除字典弹窗
            this.deleteId = row.id
            this.deleteVisible = true
        },
        async dictAdd() { // 新增字典值
            let ruleForm = {...this.ruleForm}
            this.loading = true
            let res = await dictAdd(ruleForm)
            this.loading = false
            if (res.code == 200) {
                this.$message.success('设置成功')
                this.addEditVisible = false
                this.dictSetList()
            }
        },
        addEditBtn (row) {
            if (row) {
                this.ruleForm = {
                    id: row.id,
                    elementName: row.elementName,
                    dataValue: row.dataValue,
                    sort: row.sort,
                    remark: row.remark,
                }
            } else {
                this.ruleForm = {
                    elementName: '',
                    dataValue: '',
                    sort: '',
                    remark: '',
                }
                if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
            }
            this.addEditVisible = true
        },
        async dictSetList () { // 质检记录列表
            let params = {...this.params}
            this.loading = true
            let { data } = await dictSetList(params)
            this.loading = false
            this.pagination.total = data.total;
            this.tableData = data.list;
        },
        detailBtn (id) { // 查看详情按钮
            this.$router.push(`/dictionaries/dict-setting/${id}`)
        },
        resetBtn() {// 重置
            this.params = {
                page: 1,
                pageSize: 10,
            };
            this.dictSetList()
        },
        pageFun(e, type) { // 分页
            this.params[type] = e;
            this.dictSetList();
        },
    }
}
</script>
<style lang="less" scoped>
.dictSetting-container {
    text-align: left;
}
</style>